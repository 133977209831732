import { getImageUrl, getSrcSetUrl } from '@snapchat/mw-common/client';
import { useContext, useEffect } from 'react';

import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { AssetAllType } from '../../generated/contentful-types';
import type { MediaPreloadSettings } from './mediaUtils';
import { isImageUrl, isVideoUrl } from './mediaUtils';

/**
 * Hook to preload images from contentful. Pre-loading images not from contentful will break because
 * we use the Image API to get certain formats and sizes.
 */
export const useMediaPreload = (
  media?: Array<AssetAllType>,
  isImportant = false,
  settings?: MediaPreloadSettings
): void => {
  const { onError } = useContext(GlobalComponentsContext);

  useEffect(() => {
    if (!document || !media) {
      return;
    }

    if (settings?.skipPreload?.()) {
      return;
    }

    if (isImportant) {
      const preloads = media.map(asset => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = isVideoUrl(asset.contentType) ? 'video' : 'image';
        link.href = asset.url;
        return link;
      });

      document.head.append(...preloads);
    } else {
      for (const asset of media) {
        if (isVideoUrl(asset.contentType)) {
          const video = document.createElement('video');
          const source = document.createElement('source');
          source.type = asset.contentType;
          source.src = asset.url;
          video.appendChild(source);
          video.load();
        } else if (isImageUrl(asset.contentType)) {
          if (!asset.url) return;
          const picture = document.createElement('picture');
          const avifSrc = document.createElement('source');
          const webpSrc = document.createElement('source');
          const img = document.createElement('img');

          if (asset.url.endsWith('.svg')) {
            //No load necessary. this triggers browsers to load the img based on source
            img.src = asset.url;
          } else if (settings?.sizedSrcSets) {
            const { sizedSrcSets: size } = settings;

            avifSrc.srcset = getSrcSetUrl(asset.url, { size }, 'avif');
            avifSrc.type = 'image/avif';
            webpSrc.srcset = getSrcSetUrl(asset.url, { size }, 'webp');
            webpSrc.type = 'image/webp';

            if (size.sizes) {
              avifSrc.sizes = size.sizes;
              webpSrc.sizes = size.sizes;
              img.sizes = size.sizes;
            }

            // we have to append after the srcs for source are set or else safari eagerly loads it all
            picture.appendChild(avifSrc);
            picture.appendChild(webpSrc);
            picture.appendChild(img);

            //No load necessary (or even setting src). this triggers browsers to load the img based on source
            img.srcset = getSrcSetUrl(asset.url, { size }, 'jpg');
          } else {
            avifSrc.srcset = getImageUrl({ imageUrl: asset.url, settings: { format: 'avif' } });
            avifSrc.type = 'image/avif';
            webpSrc.srcset = getImageUrl({ imageUrl: asset.url, settings: { format: 'webp' } });
            webpSrc.type = 'image/webp';

            // we have to append after the srcs for source are set or else safari eagerly loads it all
            picture.appendChild(avifSrc);
            picture.appendChild(webpSrc);
            picture.appendChild(img);

            //No load necessary. this triggers browsers to load the img based on source
            img.src = asset.url;
          }
        } else {
          onError?.(`Cannot preload assets of type ${asset.contentType}`);
        }
      }
    }
  }, [media, isImportant, onError, settings]);
};
