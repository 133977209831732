import { gql } from '@apollo/client';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { ContentDataProps } from '../../../../components/Content';
import { fragments as contentFragments } from '../../../../components/Content';
import type { FormDataProps } from '../../../../components/Form';
import { formFragments } from '../../../../components/Form';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const leadGenFormBlockQuery = gql`
  query LeadGenFormBlock($id: String!, $locale: String!, $preview: Boolean!) {
    leadGenFormBlock(id: $id, locale: $locale, preview: $preview) {
      ...ContentfulSysId
      redirectUrl
      form {
        ...FormAll
      }
      anchor
      formId
      formTitle
      content {
        ...ContentAll
      }
      backgroundColor
    }
  }
  ${formFragments.all}
  ${contentfulSysIdFragment}
  ${contentFragments.all}
`;

export interface LeadGenFormData {
  leadGenFormBlock: {
    anchor?: string;
    backgroundColor?: BackgroundColor;
    content?: ContentDataProps;
    form?: FormDataProps;
    formId?: string;
    formTitle?: string;
    redirectUrl?: string;
  };
}
