import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery, smallMobileMediaQuery } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m, p1Css, p2Css } from '../../motif';
import { globalHeaderHeight } from '../GlobalHeader/GlobalHeader.constants';

const heroMobileMinHeightPx = 256;

/**
 * Minimum height of the hero.
 *
 * Calculated to fit a standard tile image.
 */
const heroDesktopMinHeightPx = 714;

/**
 * Between mobile (768) and this, there's just not enough room to lay out the media and text in the
 * hero. So this serves as a cut-off to not show the media in these resolutions.
 */
export const cutoffWithMediaPx = 1014;

/** Max width for subTitle and body text from designs. */
const contentMaxWith = '860px';

/** Fixed height for mobile assets when Hero mimics Split Block */
// TODO: Consume mobileMediaMinHeightPx variable from SplitBlock
const heroMobileAssetHeightPx = 375;
export const heroCurtainOpacityCssVar = '--hero-curtain-opacity';

export const alignItemsStartCss = css`
  align-items: start;
`;

export const alignItemsEndCss = css`
  align-items: end;
`;

export const textContentCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: ${m('--spacing-xl')};
  padding-inline: ${m('--spacing-xl')};
  z-index: ${ZIndex.HERO_TEXT};

  ${mobileMediaQuery} {
    padding-inline: 0;
    width: 100%;
  }
`;

export const heroCss = css`
  background-color: ${m('--background-color')};
  background-image: ${m('--background-image')};

  position: relative;
  width: 100%;
  --hero-text-color-curtain-title-color: ${m('--hero-title-color')};
  --hero-text-color-curtain-subtitle-color: ${m('--hero-subtitle-color')};

  &[data-curtain='true'] {
    --hero-text-color-curtain-title-color: ${m('--hero-text-color-curtain-active')};
    --hero-text-color-curtain-subtitle-color: ${m('--hero-text-color-curtain-active')};
  }

  ${mobileMediaQuery} {
    min-height: ${heroMobileMinHeightPx}px;

    & > video {
      min-height: ${heroMobileMinHeightPx}px;
    }
  }
`;

export const boundaryCss = css`
  min-height: ${heroDesktopMinHeightPx}px;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 100%) 40%;
  grid-gap: ${m('--spacing-xl')};
  align-items: center;

  padding: ${m('--spacing-xl')} ${m('--spacing-xxxxl')};

  ${mobileMediaQuery} {
    grid-auto-flow: row;
    padding: ${m('--spacing-xl')};
    min-height: ${heroMobileMinHeightPx}px;
    max-width: 100vw;
  }

  ${smallMobileMediaQuery} {
    padding-bottom: ${m('--spacing-xl')};
  }
`;

export const compactBoundaryCss = css`
  min-height: 0px;
`;

export const curtainCss = css`
  background-color: rgba(0, 0, 0, var(${heroCurtainOpacityCssVar}, 0));
  z-index: ${ZIndex.HERO_CURTAIN};
`;

export const headerCss = css`
  color: var(--hero-text-color-curtain-title-color);
`;

export const eyebrowCss = css`
  /**
   * using var instead of m function because this is not a motif variable.
   * It is a custom css var defined in heroCss.
   */
  color: var(--hero-text-color-curtain-title-color);
  font-size: ${m('--annotation-desktop-font-size')};
  line-height: ${m('--annotation-desktop-font-line-height')};
  font-weight: ${m('--annotation-desktop-font-weight')};
  letter-spacing: ${m('--annotation-desktop-font-letter-spacing')};
  margin-block-end: ${m('--spacing-s')};

  ${mobileMediaQuery} {
    font-size: ${m('--annotation-mobile-font-size')};
    line-height: ${m('--annotation-mobile-font-line-height')};
    font-weight: ${m('--annotation-mobile-font-weight')};
    letter-spacing: ${m('--annotation-mobile-font-letter-spacing')};
  }
`;

export const titleCss = css`
  /**
 * using var instead of m function because this is not a motif variable.
 * It is a custom css var defined in heroCss.
 */
  color: var(--hero-text-color-curtain-title-color);
  margin-block: 0 ${m('--spacing-m')};

  ${mobileMediaQuery} {
    margin-block: 0 ${m('--spacing-xs')};
  }
`;

export const subTitleCss = css`
  ${p1Css}
  color: var(--hero-text-color-curtain-subtitle-color);
  margin-block-end: ${m('--spacing-m')};
  max-width: ${contentMaxWith};

  ${mobileMediaQuery} {
    margin-block-end: ${m('--spacing-xs')};
  }

  /** Overriding margin added from renderRichTextWithEmbeddingsNoHeadings*/
  & p {
    margin: 0;
  }
`;

export const bodyCss = css`
  ${p2Css}
  color: var(--hero-text-color-curtain-subtitle-color);
  margin-block-end: ${m('--spacing-m')};
  max-width: ${contentMaxWith};

  ${mobileMediaQuery} {
    margin-block-end: ${m('--spacing-xs')};
  }
  /** Overriding margin added from renderRichTextWithEmbeddingsNoHeadings */
  & p {
    margin-bottom: 0;
  }

  /**
   * If the body has multiple paragraphs, we need to add a top margin.
   * Do not add a margin bottom to avoid extra white space by adding the paragraph and parent margins.
   */
  & p + p {
    margin-block-start: ${m('--spacing-s')};
  }
`;

export const callsToActionWrapperCss = css`
  display: flex;
  gap: ${m('--spacing-m')};
  margin-block-start: ${m('--spacing-m')};

  ${mobileMediaQuery} {
    flex-direction: column;
  }

  ${smallMobileMediaQuery} {
    width: 100%;
  }
`;

export const footerCss = css`
  ${p2Css}
  color: var(--hero-text-color-curtain-subtitle-color);
  margin-block-start: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    margin-block-start: ${m('--spacing-l')};
    text-align: center;
  }
`;

export const heroSocialContainerCss = css`
  position: absolute;
  bottom: calc(${m('--break-total-desktop-height')} + ${m('--spacing-xl')});
  left: ${m('--spacing-xl')};
  z-index: ${ZIndex.HERO_CURTAIN};

  button {
    margin-right: ${m('--spacing-s')};
  }

  ${mobileMediaQuery} {
    bottom: calc(${m('--break-total-mobile-height')} + ${m('--spacing-m')});
    left: ${m('--spacing-xl')};
  }
`;

export const fitWindowCss = css`
  ${nonMobileMediaQuery} {
    height: calc(100vh - ${globalHeaderHeight}px);
    max-height: 1600px;
    min-height: ${heroDesktopMinHeightPx}px;
  }
`;

export const backgroundCss = css`
  height: 100%;
  width: 100%;

  object-fit: cover;

  position: absolute;
  left: 0;
  top: 0;
`;

export const heroTitleContainerCss = css`
  ${mobileMediaQuery} {
    max-width: 100%;
    overflow-x: auto;
  }
`;

/**
 * Have to set the z-index separately on the media so it can be rendered over the background-image
 * and curtain.
 */
export const heroMediaCss = css`
  z-index: ${ZIndex.HERO_BOUNDARY};
`;

export const heroScrollButtonWrapCss = css`
  bottom: ${m('--spacing-xl')};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${ZIndex.HERO_TEXT};
`;

export const heroScrollButtonCss = css`
  background: ${m('--hero-scroll-button-bg-color')};
  border: none;
  box-shadow: none;
  transition: background 0.2s linear;

  svg {
    fill: ${m('--hero-scroll-button-fg-color')};
  }

  &:hover {
    background: ${m('--hero-scroll-button-hover-bg-color')};
    border: none;
    transform: none;

    svg {
      fill: ${m('--hero-scroll-button-hover-fg-color')};
    }
  }

  [data-curtain='true'] & {
    background: ${m('--hero-scroll-button-curtain-active-bg-color')};

    svg {
      fill: ${m('--hero-scroll-button-curtain-active-fg-color')};
    }

    &:hover {
      background: ${m('--hero-scroll-button-curtain-active-hover-bg-color')};

      svg {
        fill: ${m('--hero-scroll-button-curtain-active-hover-fg-color')};
      }
    }
  }
`;

/** Styles for Hero Split Block */

export const heroSplitCss = css`
  align-items: stretch;
  display: flex;
  justify-content: space-between;

  ${mobileMediaQuery} {
    flex-direction: column;
    flex-direction: column-reverse;
  }
`;

export const reverseSplitCss = css`
  flex-direction: row-reverse;
`;

export const splitItemCss = css`
  width: 50%;
  ${mobileMediaQuery} {
    width: 100%;
  }
`;

export const splitMediaItemCss = css`
  height: inherit;
  position: relative;

  ${mobileMediaQuery} {
    height: ${heroMobileAssetHeightPx}px;
  }
`;

export const splitMediaCss = css`
  ${mobileMediaQuery} {
    max-height: ${heroMobileAssetHeightPx}px;
  }
`;
