import type { SrcSetSizes } from '@snapchat/mw-common/client';
import { useContentfulImages } from '@snapchat/mw-global-components';
import type { CarouselV3AspectRatios } from '@snapchat/snap-design-system-marketing';
import {
  CarouselCardItem as CarouselCardItemSDS,
  mobileMaxWidth,
} from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { isStringLocalUrl } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { combineImageSources } from '../../utils/combineImageSources';
import { parseMedia } from '../../utils/media';
import { ConsumerContext } from '../ConsumerContextProvider';
import { imageQuality } from '../Image';
import type { ImageDataProps } from '../Image/types';
import type { CarouselV3CardItemProps } from './types';

const portraitCardImgSrcSetSizes: SrcSetSizes = {
  sizeToUrl: [
    {
      size: '600w',
      settings: { width: 600 },
    },
  ],
  sizes: '600px',
};

const cardWidths = [622, 728];
const landscapeCardImgSrcSetSizes: SrcSetSizes = {
  sizeToUrl: cardWidths.map(width => ({
    size: `${width}w`,
    settings: { width },
  })),
  sizes: `(max-width: ${mobileMaxWidth}px) 622px, 728px`,
};

export const CarouselV3ImageItem: FC<{
  cardItem: CarouselV3CardItemProps;
  mediaAspectRatio: CarouselV3AspectRatios;
  isVisible?: boolean;
  shouldLoad?: boolean;
  className?: string;
}> = ({ cardItem, mediaAspectRatio, isVisible, shouldLoad, ...otherProps }) => {
  const imageMedia = cardItem.media as ImageDataProps;

  const { imageSource, imageAltText } = parseMedia(imageMedia.media);
  const { imageSource: mobileImageSource } = parseMedia(imageMedia.mobileMedia);
  const { getImageSources } = useContentfulImages();

  const { logEvent } = useContext(ConsumerContext);

  const imgSettings =
    mediaAspectRatio === '9:16' ? portraitCardImgSrcSetSizes : landscapeCardImgSrcSetSizes;

  const imageSrcSettings = {
    size: imgSettings,
    quality: imageQuality.default,
  };

  const imgSrcs = combineImageSources({
    desktop: getImageSources(imageSource, imageSrcSettings),
    /* TODO(kpan2): These aren't good for mobile images. */
    mobile: getImageSources(mobileImageSource, imageSrcSettings),
  });

  const slug = cardItem.slugReference?.slug;
  const url = slug ?? cardItem.url;

  const onClick = (): void => {
    if (!url) {
      return;
    }

    logEvent?.({
      type: UserAction.Click,
      component: 'CarouselV3',
      label: `CarouselV3.ImageItem - ${url}`,
      url,
    });

    if (!isStringLocalUrl(url)) {
      void logger.flush();
    }
  };

  return (
    <CarouselCardItemSDS
      aspectRatio={mediaAspectRatio}
      body={cardItem.body}
      key={cardItem.sys.id}
      subtitle={cardItem.subtitle}
      title={cardItem.title}
      url={url}
      imgSrcs={imgSrcs}
      imgAltText={imageAltText}
      isVisible={isVisible}
      shouldLoad={shouldLoad}
      onClick={onClick}
      {...otherProps}
    />
  );
};

CarouselV3ImageItem.displayName = 'CarouselV3ImageItem';
