import type { CarouselInternalState, MultiCarouselProps } from '../types';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { getPartialVisibilityGutter, getWidthFromDeviceType } from './elementWidth';

function notEnoughChildren(state: CarouselInternalState): boolean {
  const { slidesToShow, totalItems } = state;
  return totalItems < slidesToShow;
}

function getInitialState(
  state: CarouselInternalState,
  props: MultiCarouselProps
): {
  shouldRenderOnSSR: boolean;
  flexBisis: number | string | undefined;
  domFullyLoaded: boolean;
  partialVisibilityGutter: number | undefined;
  shouldRenderAtAll: boolean;
} {
  const { domLoaded, slidesToShow, containerWidth, itemWidth } = state;
  // old wrongly spelt partialVisbile prop kept to not make changes breaking
  const { deviceType, responsive, ssr, partialVisbile, partialVisible } = props;
  let flexBisis: number | string | undefined;
  const domFullyLoaded = Boolean(domLoaded && slidesToShow && containerWidth && itemWidth);

  if (ssr && deviceType && !domFullyLoaded) {
    flexBisis = getWidthFromDeviceType(deviceType, responsive);
  }
  const shouldRenderOnSsr = Boolean(ssr && deviceType && !domFullyLoaded && flexBisis);
  const partialVisibilityGutter = getPartialVisibilityGutter(
    responsive,
    partialVisbile || partialVisible,
    deviceType,
    state.deviceType
  );
  const shouldRenderAtAll = shouldRenderOnSsr || domFullyLoaded;
  return {
    shouldRenderOnSSR: shouldRenderOnSsr,
    flexBisis,
    domFullyLoaded,
    partialVisibilityGutter,
    shouldRenderAtAll,
  };
}

function getIfSlideIsVisbile(index: number, state: CarouselInternalState): boolean {
  const { currentSlide, slidesToShow } = state;
  return index >= currentSlide && index < currentSlide + slidesToShow;
}

function getTransformForCenterMode(
  state: CarouselInternalState,
  props: MultiCarouselProps,
  transformPlaceHolder?: number
): number {
  const transform = transformPlaceHolder || state.transform;

  if ((!props.infinite && state.currentSlide === 0) || notEnoughChildren(state)) {
    return transform;
  }

  return transform + state.itemWidth / 2;
}

function isInLeftEnd(state: CarouselInternalState): boolean {
  const { isRTL, currentSlide, totalItems, slidesToShow } = state;
  return isRTL ? !(currentSlide + slidesToShow < totalItems) : !(currentSlide > 0);
}

function isInRightEnd(state: CarouselInternalState): boolean {
  const { isRTL, currentSlide, totalItems, slidesToShow } = state;
  return isRTL ? !(currentSlide > 0) : !(currentSlide + slidesToShow < totalItems);
}

function getTransformForPartialVsibile(
  state: CarouselInternalState,
  partialVisibilityGutter: number | undefined,
  props: MultiCarouselProps,
  transformPlaceHolder?: number
): number {
  const { currentSlide, slidesToShow } = state;
  const isRightEndReach = isInRightEnd(state);
  const shouldRemoveRightGutter = !props.infinite && isRightEndReach;
  const baseTransform = transformPlaceHolder || state.transform;

  if (notEnoughChildren(state)) {
    return baseTransform;
  }
  const transform = baseTransform + currentSlide * (partialVisibilityGutter ?? 0);

  if (shouldRemoveRightGutter) {
    const remainingWidth =
      state.containerWidth - (state.itemWidth - (partialVisibilityGutter ?? 0)) * slidesToShow;

    return transform + remainingWidth;
  }

  return transform;
}

function getTransform(
  state: CarouselInternalState,
  props: MultiCarouselProps,
  transformPlaceHolder?: number
): number {
  // old wrongly spelt partialVisbile prop kept to not make changes breaking
  const { partialVisbile, partialVisible, responsive, deviceType, centerMode } = props;
  const transform = transformPlaceHolder || state.transform;
  const partialVisibilityGutter = getPartialVisibilityGutter(
    responsive,
    partialVisbile || partialVisible,
    deviceType,
    state.deviceType
  );
  const currentTransform =
    partialVisible || partialVisbile
      ? getTransformForPartialVsibile(state, partialVisibilityGutter, props, transformPlaceHolder)
      : centerMode
      ? getTransformForCenterMode(state, props, transformPlaceHolder)
      : transform;

  return currentTransform;
}

function getSlidesToSlide(state: CarouselInternalState, props: MultiCarouselProps): number {
  const { domLoaded, slidesToShow, containerWidth, itemWidth } = state;
  const { deviceType, responsive } = props;
  let slidesToScroll = props.slidesToSlide || 1;
  const domFullyLoaded = Boolean(domLoaded && slidesToShow && containerWidth && itemWidth);
  const ssr = props.ssr && props.deviceType && !domFullyLoaded;

  if (ssr) {
    Object.keys(responsive).forEach(device => {
      const { slidesToSlide } = responsive[device]!;

      if (deviceType === device && slidesToSlide) {
        slidesToScroll = slidesToSlide;
      }
    });
  }

  if (domFullyLoaded) {
    Object.keys(responsive).forEach(item => {
      const { breakpoint, slidesToSlide } = responsive[item]!;
      const { max, min } = breakpoint;

      if (slidesToSlide && window.innerWidth >= min && window.innerWidth <= max) {
        slidesToScroll = slidesToSlide;
      }
    });
  }

  return slidesToScroll;
}

export {
  getIfSlideIsVisbile,
  getInitialState,
  getSlidesToSlide,
  getTransform,
  getTransformForCenterMode,
  getTransformForPartialVsibile,
  isInLeftEnd,
  isInRightEnd,
  notEnoughChildren,
};
