import type { NodeRenderer, Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import type { Asset, Entry } from '@snapchat/mw-contentful-schema';
import get from 'lodash-es/get';
import keyBy from 'lodash-es/keyBy';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import type React from 'react';

import { AssetEntryHyperlink } from '../../components/Hyperlink/AssetEntryHyperlink';
import { logError } from '../../helpers/logging';
import type { ContentfulSysProps } from '../../types/contentful';
import type { RenderData, RichText } from '../../types/RichText';
import type { ContentfulComponentMap } from '../contentfulComponentMap';
import { contentfulComponentMap } from '../contentfulComponentMap';
import type { GetRichTextOptionsProps, MarkRenderer, RenderTextProps } from './types';

const defaultMarkRenderer: MarkRenderer = text => text;

const defaultNodeRenderer: NodeRenderer = (node, children) => {
  if (!children) {
    logError({
      component: 'RenderText',
      message: 'Default node renderer cannot render this node.',
      context: { node: JSON.stringify(node) },
    });

    return null;
  }

  return <>{children}</>;
};

const getRichTextMarkRenderer = (Component?: FC<PropsWithChildren>): MarkRenderer =>
  Component ? (text): ReactNode => <Component>{text}</Component> : defaultMarkRenderer;

const getRichTextNodeRenderer = (Component?: FC<PropsWithChildren>): NodeRenderer =>
  Component
    ? (node, children): ReactNode => <Component {...node.data}>{children}</Component>
    : defaultNodeRenderer;

const getEmbeddedEntryNodeRenderer = (
  contentfulComponentMap: ContentfulComponentMap,
  entries?: ContentfulSysProps[]
): NodeRenderer => {
  // eslint-disable-next-line react/display-name
  return (node): ReactNode => {
    const entriesById = keyBy(entries, entry => entry.sys.id);

    const entryId = node.data.target.sys.id;
    const entry = entriesById[entryId];
    const typename = entry?.__typename;

    if (!typename) {
      // If the typename wasn't requested in the query. Default render node.
      logError({
        component: 'RenderText',
        message: `Node typename wasn't in the query response`,
        context: { entryId },
      });

      return defaultNodeRenderer(node, null);
    }

    if (typename in contentfulComponentMap) {
      // @ts-ignore: typename guaranteed to be in the component map.
      const Component = contentfulComponentMap[typename];
      return <Component {...entry} />;
    }

    // If no component is specified, then default render.
    logError({
      component: 'RenderText',
      message: 'No rich text renderer found',
      context: { typename },
    });

    return defaultNodeRenderer(node, null);
  };
};

const isRichText = (data: RenderData): data is RichText => {
  return (data as unknown as RichText).json !== undefined;
};

interface GetEmbeddedProps {
  data: RenderData;
  contentfulComponentMap: ContentfulComponentMap;
  accessPath: string;
}

const getEmbeddedNodeRenderer = (props: GetEmbeddedProps): NodeRenderer => {
  const { data, contentfulComponentMap, accessPath } = props;
  let nodeRenderer: NodeRenderer;

  if (!data || !isRichText(data)) {
    nodeRenderer = defaultNodeRenderer;
  } else {
    const linkedEntries = get(data, accessPath);
    nodeRenderer = getEmbeddedEntryNodeRenderer(contentfulComponentMap, linkedEntries);
  }

  return nodeRenderer;
};

const getHyperlinkedNodeRenderer = (props: {
  data: RenderData;
  accessPath: string;
}): NodeRenderer => {
  const { data, accessPath } = props;

  if (!data || !isRichText(data)) {
    return defaultNodeRenderer;
  }

  // eslint-disable-next-line react/display-name
  return (node, children): ReactNode => {
    const linkedItems = get(data, accessPath) as Array<Asset> | Array<Entry>;
    const itemsById = keyBy(linkedItems, item => item.sys.id);
    const item = itemsById[node.data.target.sys.id]!;

    return <AssetEntryHyperlink item={item}>{children}</AssetEntryHyperlink>;
  };
};

const getRichTextOptions = (props: GetRichTextOptionsProps): Options => {
  const { data, components } = props;

  const blockEmbeddedAssetNodeRenderer = getEmbeddedNodeRenderer({
    data,
    contentfulComponentMap,
    accessPath: 'links.assets.block',
  });
  const inlineEmbeddedEntryNodeRenderer = getEmbeddedNodeRenderer({
    data,
    contentfulComponentMap,
    accessPath: 'links.entries.inline',
  });
  const blockEmbeddedEntryNodeRenderer = getEmbeddedNodeRenderer({
    data,
    contentfulComponentMap,
    accessPath: 'links.entries.block',
  });
  const hyperlinkedAssetNodeRenderer = getHyperlinkedNodeRenderer({
    data,
    accessPath: 'links.assets.hyperlink',
  });
  const hyperlinkedEntryNodeRenderer = getHyperlinkedNodeRenderer({
    data,
    accessPath: 'links.entries.hyperlink',
  });

  return {
    renderText: components.Text ?? defaultMarkRenderer,
    renderMark: {
      [MARKS.BOLD]: getRichTextMarkRenderer(components.Bold),
      // TODO: This should be using the code component.
      [MARKS.CODE]: defaultMarkRenderer,
      [MARKS.ITALIC]: getRichTextMarkRenderer(components.Italics),
      [MARKS.UNDERLINE]: getRichTextMarkRenderer(components.Underline),
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: getRichTextNodeRenderer(components.Document),
      [BLOCKS.EMBEDDED_ASSET]: blockEmbeddedAssetNodeRenderer,
      [BLOCKS.EMBEDDED_ENTRY]: blockEmbeddedEntryNodeRenderer,
      [BLOCKS.HEADING_1]: getRichTextNodeRenderer(components.H1),
      [BLOCKS.HEADING_2]: getRichTextNodeRenderer(components.H2),
      [BLOCKS.HEADING_3]: getRichTextNodeRenderer(components.H3),
      [BLOCKS.HEADING_4]: getRichTextNodeRenderer(components.H4),
      [BLOCKS.HEADING_5]: getRichTextNodeRenderer(components.H5),
      [BLOCKS.HEADING_6]: getRichTextNodeRenderer(components.H6),
      // TODO: We should have an HR renderer.
      [BLOCKS.HR]: defaultNodeRenderer,
      [BLOCKS.LIST_ITEM]: getRichTextNodeRenderer(components.ListItem),
      [BLOCKS.PARAGRAPH]: getRichTextNodeRenderer(components.Paragraph),
      // TODO: This should be using the Quote component.
      [BLOCKS.QUOTE]: defaultNodeRenderer,
      [BLOCKS.UL_LIST]: getRichTextNodeRenderer(components.UnorderedList),
      [BLOCKS.OL_LIST]: getRichTextNodeRenderer(components.OrderedList),
      [INLINES.ASSET_HYPERLINK]: hyperlinkedAssetNodeRenderer,
      [INLINES.ENTRY_HYPERLINK]: hyperlinkedEntryNodeRenderer,
      [INLINES.EMBEDDED_ENTRY]: inlineEmbeddedEntryNodeRenderer,
      [INLINES.HYPERLINK]: getRichTextNodeRenderer(components.Hyperlink),
      // TODO: Upgrade to Contentful v16 to enable "table" enums
      table: getRichTextNodeRenderer(components.Table),
      'table-cell': getRichTextNodeRenderer(components.TableCell),
      'table-header-cell': getRichTextNodeRenderer(components.TableHeader),
      'table-row': getRichTextNodeRenderer(components.TableRow),
    },
  };
};

/**
 * Creater a render for rich text.
 *
 * TODO: We shouldn't support "string" as input type here. This only happens when we haven't updated
 * the interfaces to reflect the type of data that is coming from contentful.
 */
export const createRichTextRenderer =
  (props: RenderTextProps) =>
  (data: RenderData): React.ReactNode => {
    const { components = {} } = props;

    if (!data) {
      return undefined;
    }

    if (typeof data === 'string') {
      return data;
    }

    const options = getRichTextOptions({
      data,
      components,
    });

    return documentToReactComponents(data.json, options);
  };
