import { gql } from '@apollo/client';

import { ctaFragment } from '../CallToAction';
import { fragments as imageFragments } from '../Image';
import { fragments as mediaFragments } from '../Media';
import { snapchatEmbedFragment } from '../SnapchatEmbed';
import { fragments as videoFragments } from '../Video';

export const fragments = {
  all: gql`
    fragment BlockHeroAll on BlockHero {
      ...ContentfulSysId
      header {
        json
      }
      eyebrow
      title {
        json
      }
      subTitle {
        json
      }
      body {
        json
      }
      callsToActionCollection(limit: 3) {
        items {
          ... on CallToAction {
            ...CallToActionAll
          }
        }
      }
      foreground {
        __typename
        ... on Image {
          ...ImageAll
        }
        ... on Video {
          ...VideoAll
        }
        ... on CarouselV3 {
          ...ContentfulSysId
        }
        ... on SnapchatEmbed {
          ...SnapchatEmbedAll
        }
      }
      backgroundMediaLayout
      backgroundMediaV2 {
        __typename
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
      headerMediaV2 {
        __typename
        ... on Image {
          ...ImageAll
        }
      }
      mediaWrap
      isHeaderDate
      theme
      backgroundColor
      textAlign
      textAlignMobile
      verticalTextAlign
      fitWindow
      curtainOpacity
      curtainOpacityPercentage
      useLatestPost
      showMediaMobile
      anchorId
      size
      showScrollButton
    }
    ${ctaFragment}
    ${videoFragments.all}
    ${imageFragments.all}
    ${mediaFragments.all}
    ${snapchatEmbedFragment}
  `,
};

export const blockHeroQuery = gql`
  query BlockHeroQuery($preview: Boolean!, $locale: String!, $id: String!) {
    blockHero(preview: $preview, locale: $locale, id: $id) {
      ...BlockHeroAll
    }
  }
  ${fragments.all}
`;
