import { ensureSingleScript } from '@snapchat/mw-common/client';
import type { MapOf } from '@snapchat/snap-design-system-marketing';
import { BackgroundColor, Block } from '@snapchat/snap-design-system-marketing';
import cloneDeep from 'lodash/cloneDeep.js';
import type { FC, ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../../AppContext';
import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import type { ContentfulInputField } from '../../../../components/Form';
import { Form } from '../../../../components/Form/Form';
import { logError } from '../../../../helpers/logging';
import { renderRichTextMarkingsOnly } from '../../../../utils/renderText/renderRichText';
import type { ChiliPiperFormDataProps } from './ChiliPiperFormQuery';

/** The url parameters we want to capture and send to Chili Piper */
const urlParametersToSubmit = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
  'leadsource', // Determines the conversion behavior in SalesForce
  'SFDC_contactID', // SalesForce contact record id
  'SFDC_accountID', // SalesForce account record id
];

/**
 * Maps Snap supported locale to a Chili Piper formatted locale where possible.
 *
 * Chili Piper lacks support for the following Snap supported locales: bn-BD, bn-IN, es, es-AR,
 * es-MX, fi-FI, fil-PH, gu-IN, hi-IN, kn-IN, ml-IN, mr-IN, ms-MY, pa, ta-IN, te-IN, ur-PK
 *
 * If an unsupported locale is given, returns an alternative if possible, otherwise defaults to
 * en_US.
 *
 * Snap Locales: https://wiki.sc-corp.net/display/Engineering/Consumer+Product+Localization Chili
 * Piper Locales: https://help.chilipiper.com/hc/en-us/articles/360053799733
 */
const convertSnapLocaleToChiliPiperLocale = (locale: string) => {
  const snapToChiliPiperLocale = {
    ar: 'ar_AR',
    'da-DK': 'da_DK',
    'de-DE': 'de_DE',
    'el-GR': 'el_GR',
    'en-US': 'en_US',
    'en-GB': 'en_GB',
    es: 'es_ES', // Chili Piper does not support international Spanish, next best is Spain Spanish
    'es-AR': 'en_LA', // Chili Piper does not support Argentinian Spanish, next best is Latin American Spanish
    'es-ES': 'es_ES',
    'es-MX': 'en_LA', // Chili Piper does not support Mexican Spanish, next best is Latin American Spanish
    'fr-FR': 'fr_FR',
    'id-ID': 'id_ID',
    'it-IT': 'it_IT',
    'ja-JP': 'ja_JP',
    'ko-KR': 'ko_KR',
    'nb-NO': 'nb_NO',
    'nl-NL': 'nl_NL',
    'pl-PL': 'pl_PL',
    'pt-BR': 'pt_BR',
    'pt-PT': 'pt_PT',
    'ro-RO': 'ro_RO',
    'ru-RU': 'ru_RU',
    'sv-SE': 'sv_SE',
    'th-TH': 'th_TH',
    'tr-TR': 'tr_TR',
    'vi-VN': 'vi_VN',
    'zh-Hans': 'zh_CN',
    'zh-Hant': 'zh_TW',
  };

  return snapToChiliPiperLocale[locale as keyof typeof snapToChiliPiperLocale] ?? 'en_US';
};

const generateHiddenField = (
  formId: string,
  name: string,
  nameToValue: MapOf<string>
): ContentfulInputField => ({
  __typename: 'InputField',
  _id: 'generated',
  name,
  sys: { id: `${formId}-field-${name}` },
  type: 'Hidden',
  initialValue: nameToValue[name],
});

export interface ChiliPiperFormBlockProps extends ChiliPiperFormDataProps {
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}

/**
 * The Chili Piper form block is mostly just a fancy wrapper around a standard MWP form.
 *
 * It injects the Chili Piper JS API which intercepts the form submission and routes the payload
 * through the Chili Piper meeting booking flow.
 *
 * Note: The Form entry given to this component must have inputs that match whatever the configured
 * router expects. For example, if the router expects "zipcode" and "state", then the Form entry
 * should have inputs with names "zipcode" and "state".
 */
export const ChiliPiperFormBlock: FC<ChiliPiperFormBlockProps> = ({
  title,
  subtitle,
  form,
  formId,
  routerName,
  confirmationTitle,
  confirmationText,
  backgroundColor = BackgroundColor.White,
  anchorId,
  preChildren,
  postChildren,
}) => {
  const { getUrlParams } = useContext(ConsumerContext);
  const [isMeetingBooked, setIsMeetingBooked] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { currentLocale } = useContext(AppContext);

  useEffect(() => {
    // Externally hosted chili piper js uses an unsafe-inline script tag.
    // We are self hosting our own customized version without the inline-script */
    ensureSingleScript('chili-piper-marketing', '/chili-piper-marketing.js', () =>
      setScriptLoaded(true)
    );
  }, [setScriptLoaded]);

  useEffect(() => {
    // Listen for chili piper calendar availability to load
    // to close loading spinner
    window.addEventListener(
      'message',
      event => {
        if (event.data.action === 'availability-loaded' || event.data.action === 'no-free-slots') {
          const loadingImage = document.querySelector<HTMLElement>('#loadImg');

          if (loadingImage != null) {
            loadingImage.style.display = 'none';
          }
        }
      },
      false
    );
  });

  const onFormSubmit = () => {
    const chiliPiperLocale = convertSnapLocaleToChiliPiperLocale(currentLocale);

    window.ChiliPiper?.submit('snapchat', routerName!, {
      formId,
      locale: chiliPiperLocale,
      onSuccess: () => setIsMeetingBooked(true), // Submission event is logged by underlying Form component
      onError: (error: Error) => {
        logError({
          component: 'ChiliPiper',
          error,
          message: `Chili Piper scheduling failed: ${error}`,
        });
      },
    });
  };

  if (!scriptLoaded) return null;

  // Create a form row which houses a hidden field for each
  // url param we must capture and send
  const editableForm = cloneDeep(form!);
  const urlParamMap = getUrlParams?.() ?? {};
  const hiddenFieldsRow = {
    fieldsCollection: {
      items: urlParametersToSubmit.map(urlParam =>
        generateHiddenField(formId!, urlParam, urlParamMap)
      ),
    },
    sys: { id: `${formId}-url-params-row` },
  };

  // Add new row with url params to rows configured in contentful
  editableForm.rowsCollection.items.push(hiddenFieldsRow);
  const formPropsWithId = {
    id: formId,
    ...editableForm,
  };

  // Remove the redirect url since redirection is handled by chili piper
  formPropsWithId.redirectUrl = undefined;

  return (
    <>
      <Block
        title={isMeetingBooked ? confirmationTitle : title}
        subtitle={renderRichTextMarkingsOnly(isMeetingBooked ? confirmationText : subtitle)}
        backgroundColor={backgroundColor}
        anchorId={anchorId}
        preChildren={preChildren}
        postChildren={postChildren}
      >
        {!isMeetingBooked && <Form {...formPropsWithId} callback={onFormSubmit} />}
      </Block>
    </>
  );
};
