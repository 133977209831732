import { css } from '@emotion/css';
import { m, mobileMediaQuery, MotifComponent } from '@snapchat/snap-design-system-marketing';

export const formSectionCss = css`
  ${mobileMediaQuery} {
    order: -1;
  }
`;

export const formDivCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-m')};
  align-items: center;
`;

/**
 * We need this override because the form will almost always stretch below the content. This is
 * problematic because now the text and image has a bunch of white space betwee it without this
 * rule. Content does not expose className, so I added this rule to the wrapper of this custom
 * component.
 */
export const leadGenContentBottomOverrideCss = css`
  .${MotifComponent.CONTENT} {
    grid-template-rows: auto 1fr;
  }
`;

export const contentCss = css`
  border-radius: ${m('--border-radius-l')};
  padding: ${m('--content-desktop-no-bg-padding')};
  ${mobileMediaQuery} {
    padding: ${m('--content-mobile-no-bg-padding')};
  }
`;
