/* eslint-disable @typescript-eslint/no-unused-vars */
import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';
import { useContext, useRef } from 'react';

import type { BackgroundColor } from '../../constants';
import { Alignment } from '../../constants';
import { MotifComponent, p1Css, useMotifStyles } from '../../motif';
import type { BaseComponentProps, Buttons } from '../../types';
import {
  BlockBackgroundColorContext,
  dataSetToAttributes,
  getBackgroundClassName,
} from '../../utils';
import { hideWhenDesktopCss, hideWhenMobileCss } from '../../utils/styles';
import { useAnimations } from '../Animations';
import type { Animation } from '../Animations/types';
import {
  bodyContainerCss,
  bodyContainerStretchCss,
  bodyMarginTopCss,
  bodyStyleCss,
  contentBaseCss,
  contentLayoutCss,
  contentLayoutDirectionCss,
  contentWrapperNoMobilePaddingStyle,
  contentWrapperStyle,
  ctaContainerCss,
  ctaInnerCss,
  ctaMarginTopCss,
  displayNoneCss,
  headerCss,
  mediaOrderCss,
  subtitleBaseCss,
  textAlignmentBaseCss,
  textAlignmentDesktopStyle,
  textAlignmentMobileStyle,
  titleCss,
  transparentBackgroundCss,
} from './Content.styled';
import { DisplayMode, Layout, MediaDirection } from './types';

export interface ContentProps extends BaseComponentProps {
  body?: ReactNode;
  bodyAlignment?: Alignment;
  bodyAlignmentMobile?: Alignment;
  callsToAction?: Buttons;
  displayOn?: DisplayMode;
  layout?: Layout;
  mediaDirection?: MediaDirection;
  subtitle?: ReactNode;
  title?: ReactNode;
  titleAlignment?: Alignment;
  titleAlignmentMobile?: Alignment;
  backgroundColor?: BackgroundColor;
  animations?: Animation[];
  isRTL?: boolean;
  bodyDataset?: DOMStringMap;
  titleDataset?: DOMStringMap;
  subtitleDataset?: DOMStringMap;
  /** @deprecated. Use `asset` instead. */
  media?: ReactNode;
  asset?: ReactNode;
}

// TODO: when we fully deprecate old Content, update the props
/** Newer content component that is cleaner and has removed lots of rarely used functionality */
export const Content: FC<ContentProps> = ({
  className,
  body,
  bodyAlignment = Alignment.Start,
  bodyAlignmentMobile = Alignment.Start,
  callsToAction,
  displayOn = DisplayMode.ALL,
  layout = Layout.NORMAL,
  mediaDirection = MediaDirection.TOP,
  subtitle,
  title,
  titleAlignment = Alignment.Start,
  titleAlignmentMobile = Alignment.Start,
  backgroundColor: contentBackgroundColor,
  animations,
  isRTL,
  bodyDataset,
  titleDataset,
  subtitleDataset,
  asset,
  style,
}) => {
  useMotifStyles(MotifComponent.CONTENT);
  const contentRef = useRef(null);
  const animationProps = useAnimations(contentRef, animations, 'content');

  const dir = isRTL ? 'rtl' : undefined;

  const blockBackgroundColor = useContext(BlockBackgroundColorContext);
  const backgroundColor = contentBackgroundColor || blockBackgroundColor;
  const isSameBackgroundColorAsBlock = blockBackgroundColor === backgroundColor;

  // helper variables
  const hasHeader = !!(title || subtitle);
  const hasCallsToAction = !!(
    (callsToAction && !Array.isArray(callsToAction)) ||
    (Array.isArray(callsToAction) && callsToAction.length)
  );
  const hasBody = body || hasCallsToAction;
  const hasContent = !!(title || subtitle || hasBody);
  const hasMediaAndContent = !!asset && hasContent;

  // Styles for the main <section> container
  const bgClass = contentBackgroundColor ? getBackgroundClassName(backgroundColor) : undefined;

  // Remove padding if the block BackgroundColor is the same as the content BackgroundColor
  const contentBoxStyle =
    backgroundColor === blockBackgroundColor
      ? contentWrapperNoMobilePaddingStyle[layout]
      : contentWrapperStyle[layout];

  const sectionClassName = cx(
    MotifComponent.CONTENT,
    // style for background color class name
    bgClass,
    // base css that is always applied
    contentBaseCss,
    // special padding css based on background color of content and its containing block
    contentBoxStyle,
    {
      // style to hide/show on certain devices based on DisplayMode
      [hideWhenMobileCss]: displayOn === DisplayMode.DESKTOP_ONLY,
      [hideWhenDesktopCss]: displayOn === DisplayMode.MOBILE_ONLY,
      [displayNoneCss]: displayOn === DisplayMode.NEITHER,
      // if the content has media and text, use proper grid layout
      [contentLayoutCss[layout] || '']: hasMediaAndContent,
      // if the content has media and text, set proper ordering on grid items for proper direction
      [contentLayoutDirectionCss[layout]?.[mediaDirection] || '']: hasMediaAndContent,
      // We use this to override the background color and set it to transparent when bg colors are the same
      // or when content bg color isn't set.
      [transparentBackgroundCss]: !contentBackgroundColor || isSameBackgroundColorAsBlock,
    },
    className
  );

  // Header Elements Section
  const titleAlignmentClassName = cx(
    textAlignmentBaseCss,
    textAlignmentDesktopStyle[titleAlignment],
    textAlignmentMobileStyle[titleAlignmentMobile]
  );

  const ContentHeader: FC = () => (
    <div data-test-id="sdsm-content-header" className={cx(headerCss, titleAlignmentClassName)}>
      {title && (
        <h3
          className={cx(titleCss, titleAlignmentClassName, animationProps?.title?.className)}
          data-test-id="sdsm-content-title"
          data-animation-id="content-title"
          data-animation-delay={animationProps?.title?.delay ?? null}
          {...dataSetToAttributes(titleDataset)}
        >
          {title}
        </h3>
      )}
      {subtitle && (
        <div
          className={cx(p1Css, subtitleBaseCss, animationProps?.subtitle?.className)}
          data-test-id="sdsm-content-subtitle"
          data-animation-id="content-subtitle"
          data-animation-delay={animationProps?.subtitle?.delay ?? null}
          {...dataSetToAttributes(subtitleDataset)}
        >
          {subtitle}
        </div>
      )}
    </div>
  );

  // Body Section
  const bodyAlignmentClassName = cx(
    textAlignmentBaseCss,
    textAlignmentDesktopStyle[bodyAlignment],
    textAlignmentMobileStyle[bodyAlignmentMobile]
  );

  const BodyContent: FC = () => (
    <div data-test-id="sdsm-content-body">
      {body && (
        <div
          className={cx(bodyStyleCss, bodyAlignmentClassName, {
            [bodyMarginTopCss]: hasHeader,
          })}
          data-test-id="sdsm-content-body-content"
          data-animation-id="content-body"
          data-animation-delay={animationProps?.body?.delay ?? null}
          {...dataSetToAttributes(bodyDataset)}
        >
          {body}
        </div>
      )}

      {hasCallsToAction && (
        <div data-test-id="sdsm-content-cta" className={cx(ctaContainerCss)}>
          <div
            className={cx(ctaInnerCss, bodyAlignmentClassName, animationProps?.cta?.className, {
              [ctaMarginTopCss]: !!body || hasHeader,
            })}
            data-animation-id="content-cta"
            data-animation-delay={animationProps?.cta?.delay ?? null}
          >
            {callsToAction}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <section
      style={style}
      data-test-id={MotifComponent.CONTENT}
      className={sectionClassName}
      ref={contentRef}
      data-animation-delay={animationProps?.container?.delay ?? null}
      dir={dir}
    >
      {!!asset && (
        <div data-test-id="sdsm-content-media-container" className={mediaOrderCss[mediaDirection]}>
          <div
            data-test-id="sdsm-content-media"
            className={animationProps?.media?.className}
            data-animation-id="content-media"
            data-animation-delay={animationProps?.media?.delay ?? null}
          >
            {asset}
          </div>
        </div>
      )}
      {hasContent && (
        <div
          data-test-id="sdsm-content-header-and-body"
          className={cx(bodyContainerCss, {
            [bodyContainerStretchCss]:
              mediaDirection === MediaDirection.TOP || mediaDirection === MediaDirection.BOTTOM,
          })}
        >
          {hasHeader && <ContentHeader />}
          {hasBody && <BodyContent />}
        </div>
      )}
    </section>
  );
};
