import { cx } from '@emotion/css';
import { Block, H2, MediaDirection } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { Content } from '../../../../components/Content';
import { Form } from '../../../../components/Form/Form';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import {
  contentCss,
  formDivCss,
  formSectionCss,
  leadGenContentBottomOverrideCss,
} from './LeadGenFormBlock.styles';
import type { LeadGenFormData } from './LeadGenFormQueries';
import { leadGenFormBlockQuery } from './LeadGenFormQueries';

export const LeadGenFormBlock: FC<{ id: string }> = ({ id }) => {
  const { data } = useContentfulQuery<LeadGenFormData, ContentfulIdVariable>(
    leadGenFormBlockQuery,
    {
      variables: {
        id,
      },
    }
  );

  if (!data) return null;

  const { leadGenFormBlock } = data;

  if (!leadGenFormBlock || !leadGenFormBlock.form) return null;

  return (
    <>
      <Block
        anchorId={leadGenFormBlock.anchor}
        backgroundColor={leadGenFormBlock.backgroundColor}
        className={cx({
          [leadGenContentBottomOverrideCss]:
            leadGenFormBlock.content?.mediaDirection === MediaDirection.BOTTOM,
        })}
      >
        {leadGenFormBlock.content && <Content {...leadGenFormBlock.content} />}
        <section className={cx(contentCss, formSectionCss)}>
          <div className={formDivCss}>
            {leadGenFormBlock.formTitle ? <H2>{leadGenFormBlock.formTitle}</H2> : null}
            <Form
              {...leadGenFormBlock.form}
              redirectUrl={leadGenFormBlock.redirectUrl ?? leadGenFormBlock?.form?.redirectUrl}
              enableArkose
              extraParams={{
                formId: leadGenFormBlock?.formId ?? '',
              }}
            />
          </div>
        </section>
      </Block>
    </>
  );
};
