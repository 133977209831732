import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { createContext } from 'react';

export enum HeaderView {
  FULL_HEADER = 'Full Header',
  /**
   * Minimized header hides all the navigation items, keeping just the snap logo and the call to
   * action buttons
   */
  MINIMIZED_HEADER = 'Minimized Header',
  NO_HEADER = 'No Headers',
  /**
   * Simple header hides all the navigation items, keeping just the snap logo and name of the
   * website
   */
  SIMPLE_HEADER = 'Simple Header',
}

/**
 * Simple footer allows to show a narrow basic footer bar with only essential links.
 *
 * NOTE: These values must match validation in contentful.
 */
export enum FooterView {
  FULL_FOOTER = 'Full Footer',
  NO_FOOTER = 'No Footer',
  SIMPLE_FOOTER = 'Simple Footer',
}

export interface PageLayoutContextProps {
  footerView: FooterView;
  headerView: HeaderView;
  headerBackgroundColorOverride?: BackgroundColor;
  setFooterView?: (footerView: FooterView) => void;
  setHeaderView?: (headerView: HeaderView) => void;
  setHeaderBackgroundColorOverride?: (color?: BackgroundColor) => void;
}

export const PageLayoutContext = createContext<PageLayoutContextProps>({
  footerView: FooterView.FULL_FOOTER,
  headerView: HeaderView.FULL_HEADER,
});
