import merge from 'lodash-es/merge';
import { useCallback } from 'react';

import { isBrowser } from '../utils/isBrowser';
import type { ContentfulImageApiParams } from './getImageUrl';
import { getImageUrl } from './getImageUrl';

export interface ImageSource {
  url: string;
  type: string;
  sizes?: string;
  media?: string;
}

export interface ImageSources {
  sources: ImageSource[];
  default: string;
  defaultSrcSet?: string;
  defaultSizes?: string;
}

/**
 * Grouping between the image size (based on width, height or DPI (x)) to the Contentful api
 * settings.
 */
interface SizeToImageParams {
  /**
   * Size breakpoints. Must be consistent with all other sizes in the same srcset, e.g. all width
   * based `w` or all dpr based `x`
   */
  size: string;
  /** Image api settings for the url at this size breakpoint. */
  settings: ContentfulImageApiParams;
}

/** Helper function that gets the best img format for a given url. */
export type GetBestImgSrc = (
  url?: string,
  settings?: ContentfulImageApiParams
) => string | undefined;

/**
 * Size setting object that specifies a size to url mapping, as well a sizes array of all sizes we
 * want to handle.
 */
export interface SrcSetSizes {
  sizeToUrl: SizeToImageParams[];
  sizes?: string;
}

/** Size settings to get srcSet with sizes. */
interface SrcSetSettingsBySizes {
  size: SrcSetSizes;
  media?: string;
  quality?: number;
}

/** Settings for getting srcSet without sizes. */

interface SrcSetSettingsByImage {
  image: ContentfulImageApiParams;
}

/**
 * Settings object that controls image settings OR size settings. If you have size and image, it
 * only checks size because each size can technically have its own image settings.
 *
 * This is used to set width/height and other properties per contentful image API.
 */
export type SrcSetSettings = SrcSetSettingsByImage | SrcSetSettingsBySizes;

/**
 * Helper function that returns an ImageSources object. Can handle sizes or no sizes based on the
 * settings passed in.
 */
export type GetImageSources = (url?: string, settings?: SrcSetSettings) => ImageSources | undefined;

export const getSrcSetUrl = (
  url: string,
  settings?: SrcSetSettings,
  // This overrides anything in settings
  format?: 'webp' | 'avif' | 'png' | 'jpg'
): string => {
  if (!url) return '';

  if (!!settings && 'size' in settings && settings.size) {
    return settings.size.sizeToUrl
      .map(({ size, settings }) => {
        if (size) {
          return `${getImageUrl({
            imageUrl: url,
            settings: { ...settings, format: format ?? settings?.format },
          })} ${size}`;
        }

        return getImageUrl({
          imageUrl: url,
          settings: { ...settings, format: format ?? settings?.format },
        });
      })
      .join(',');
  }
  const image = !!settings && 'image' in settings && settings.image ? settings.image : undefined;
  return getImageUrl({
    imageUrl: url,
    settings: {
      ...image,
      format: format ?? image?.format,
    },
  });
};

interface ContentfulImageHelpers {
  getImageSources: GetImageSources;
  getBestBgImgSrc: GetBestImgSrc;
}

/** Properties needed for determining how a contentful URL can be transformed. */
export type ImageTransformProps = {
  supportsWebP: boolean;
  supportsAvif: boolean;
  isSlowConnection: boolean;
};

/**
 * Safari 16.1, 16.2, 16.3 and 16.4 it thinks that it can play avif, but it actually can't play
 * animated avif.
 *
 * See
 * https://stackoverflow.com/questions/75868335/safari-fallback-from-avif-to-gif-using-picture-tag-not-working
 *
 * When Safari 19 comes out (Sept 2025), we can drop support for Safari 16, and this won't matter
 * anymore.
 */
function isOsxSafariAnimatedAvifBugCurrent(): boolean {
  return new Date() >= new Date('2025-10-01');
}

/**
 * Hook that returns all the helper functions you need to use sourcesets and contentful images.
 * Technically doesn't need to be a hook, but if we move BrowserFeatures to common in the future, we
 * will need to make it a hook, so leaving as is... for now.
 *
 * @returns `getImageSources`: Helper function that returns ImageSources object for using srcsets.
 *
 *   `getBestBgImgSrc`: Returns the best possible img src url for the users browser when using
 *   background-image css.
 */
export const useContentfulImages = (props: ImageTransformProps): ContentfulImageHelpers => {
  const { isSlowConnection, supportsAvif: maybeSupportsAvif, supportsWebP } = props;

  // We set `supportsAvif` to false on the server, so that we don't send a payload
  // that the client can't use for animated avifs. For older devices that means
  // they can still see the images. And for newever devices this introduces a
  // re-render on the client (instead of a rehydration), but we discussed this as
  // a team and chose this as the best option.
  const supportsAvif = isOsxSafariAnimatedAvifBugCurrent()
    ? isBrowser() && maybeSupportsAvif
    : maybeSupportsAvif;

  const getImageSources = useCallback<GetImageSources>(
    (url, settings): ImageSources | undefined => {
      if (!url) {
        return undefined;
      }

      // Override the quality setting if the connection is slow.
      if (settings && isSlowConnection) {
        if ('image' in settings) {
          merge(settings, { image: { quality: 10 } });
        } else {
          settings.quality = 10;
        }
      }

      const isSvg = url.toLowerCase().endsWith('.svg');

      let sources: ImageSource[] = [];

      if (isSvg) {
        sources = [{ type: 'image/svg+xml', url }];
      } else {
        const sizes = !!settings && 'size' in settings ? settings?.size?.sizes : undefined;
        const media = !!settings && 'media' in settings ? settings?.media : undefined;

        // It's always safe to register webP. It will be skipped if the browser doesn't support it.
        sources.unshift({
          type: 'image/webp',
          url: getSrcSetUrl(url, settings, 'webp'),
          sizes,
          media,
        });

        // Avif should override webp. Can't always add it because of the IOS Safari bug.
        if (supportsAvif) {
          sources.unshift({
            type: 'image/avif',
            url: getSrcSetUrl(url, settings, 'avif'),
            sizes,
            media,
          });
        }
      }

      let defaultSettings: SrcSetSettings | undefined =
        settings && 'image' in settings ? { image: settings?.image } : undefined;

      // If we passed in size settings, find the smallest setting and use it on the <img> tags src
      if (!!settings && 'size' in settings && settings.size) {
        const lowestSettings = settings.size.sizeToUrl.reduce((prev, cur) => {
          if (!prev) {
            return cur;
          }

          return Number.parseInt(prev.size) < Number.parseInt(cur.size) ? prev : cur;
        });
        defaultSettings = { image: lowestSettings.settings };
      }

      return {
        sources,
        // Only pass in settings if there are NO size settings, since the default url is used
        // on the <img src={here} /> so it doesn't take srcset
        default: getSrcSetUrl(url, defaultSettings),
      };
    },
    [isSlowConnection, supportsAvif]
  );

  const getBestBgImgSrc = useCallback(
    (url?: string, settings?: ContentfulImageApiParams) => {
      if (!url) {
        return undefined;
      }

      if (url.toLowerCase().endsWith('.svg')) {
        return url;
      }

      // Default to jpg since some images are png which can be large.
      const bestFormat = (supportsAvif && 'avif') || (supportsWebP && 'webp') || 'jpg';

      const finalSettings = { ...settings };

      if (bestFormat) {
        finalSettings.format = bestFormat;
      }

      return getImageUrl({ imageUrl: url, settings: finalSettings });
    },
    [supportsAvif, supportsWebP]
  );

  return {
    getImageSources,
    getBestBgImgSrc,
  };
};
