import type { FC } from 'react';

import { IntoPageBottomStickyPortal } from '../../../../components/Page/PageBottomStickyPortal';
import {
  type ContentfulIdVariable,
  useContentfulQuery,
} from '../../../../hooks/useContentfulQuery';
import { PersistentCallToAction } from './PersistentCallToAction';
import { query } from './query';
import type { PersistentCallToActionQueryData } from './types';

export const PersistentCallToActionShallow: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<PersistentCallToActionQueryData, ContentfulIdVariable>(
    query,
    {
      variables: { id: props.id },
    }
  );

  if (!data?.mobileCta) return null;

  return (
    <IntoPageBottomStickyPortal>
      <PersistentCallToAction {...data.mobileCta} />
    </IntoPageBottomStickyPortal>
  );
};
