import type { ValueOf } from '../types';

export const CurtainOpacity = {
  Zero: '0%',
  TwentyFive: '25%',
  Fifty: '50%',
  SeventyFive: '75%',
} as const;

export type CurtainOpacity = ValueOf<typeof CurtainOpacity>;
