import type { FooterGroup, FooterGroupItemsItem } from '@snapchat/mw-contentful-schema';
import { FooterType, GlobalFooter, useContentfulImages } from '@snapchat/mw-global-components';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { FooterSocial, ImageButton, Media } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { FooterView, PageLayoutContext } from '../../context/PageLayoutContext';
import { SitewideConfigurationContext } from '../../context/SitewideConfiguration/SitewideConfigurationContext';
import { Anchor } from '../Anchor';
import { useExperiment } from '../Experiment';
import { logoWrapperCss, socialAnchorCss, socialPictureCss } from './Footer.styles';
import { filterCriteria } from './utils';

type FooterWrapperProps = {
  cookieSettingsUrl: string;
};

export const Footer: FC<FooterWrapperProps> = ({ cookieSettingsUrl }) => {
  const { footerView } = useContext(PageLayoutContext);
  const { getImageSources } = useContentfulImages();

  const { sitewideValues } = useContext(SitewideConfigurationContext);

  const { checkCriteria } = useExperiment();

  const showSimpleFooter = footerView === FooterView.SIMPLE_FOOTER;
  const noFooter = footerView === FooterView.NO_FOOTER;

  let footerType: FooterType = FooterType.FULL_FOOTER;

  if (noFooter) {
    footerType = FooterType.NO_FOOTER;
  } else if (showSimpleFooter) {
    footerType = FooterType.SIMPLE_FOOTER;
  }

  if (noFooter) {
    return null;
  }

  const isVisible = (item: FooterGroup | FooterGroupItemsItem) => checkCriteria(item.criteria);

  const customFooter = sitewideValues?.customFooter;

  const filteredFooterColumnsCollection = filterCriteria(
    isVisible,
    cookieSettingsUrl,
    customFooter?.columnsCollection?.items
  );
  const filteredFooterBarCollection = filterCriteria(
    isVisible,
    cookieSettingsUrl,
    customFooter?.barCollection?.items
  );

  const footerSocial = customFooter?.socialCollection?.items?.length ? (
    <FooterSocial title={customFooter?.socialLabel ?? undefined}>
      {customFooter?.socialCollection?.items?.map((footerSocialItem, i) => {
        const { image, height, sys, url, width } = footerSocialItem;
        return (
          <ImageButton
            key={`${sys.id}-${i}`}
            url={url}
            height={height}
            imgSrcs={getImageSources(image?.url)}
            altText={image?.description}
            width={width}
            pictureClassName={socialPictureCss}
            className={socialAnchorCss}
          />
        );
      })}
    </FooterSocial>
  ) : null;

  const customLogo = customFooter?.logo;
  let footerLogo: React.ReactNode | undefined;

  if (customLogo) {
    const imgSrcs = getImageSources(customLogo.media?.url, { image: { height: 24 } });
    const logoUrl = customFooter?.url;
    const logo = (
      <Media
        sourceType={customLogo.media?.contentType}
        marginBottom={false}
        imgSrcs={imgSrcs}
        altText={customLogo.media?.description}
      />
    );

    if (logoUrl) {
      footerLogo = (
        <Anchor href={logoUrl} className={logoWrapperCss}>
          {logo}
        </Anchor>
      );
    } else {
      footerLogo = logo;
    }
  }

  return (
    <GlobalFooter
      customColumns={filteredFooterColumnsCollection}
      customFooterBar={filteredFooterBarCollection}
      cookieSettingsUrl={cookieSettingsUrl}
      backgroundColor={customFooter?.backgroundColor as BackgroundColor}
      logo={footerLogo}
      socialBar={footerSocial}
      footerType={footerType}
    />
  );
};
