import { css } from '@emotion/css';

import { maxWidth, mobileMediaQuery } from '../../constants';
import { m, p3Css } from '../../motif';

export const footnoteCss = css`
  box-sizing: border-box;
  display: flex;
  gap: 0.25em;
  margin: 0 auto ${m('--spacing-m')};
  max-width: ${maxWidth}px;
  padding: 0 ${m('--spacing-xl')};
  ${p3Css}

  ${mobileMediaQuery} {
    padding: 0 ${m('--spacing-m')};
  }
`;

export const footnoteSuperscriptCss = css`
  font-size: 10px;
  line-height: 18px;
`;
